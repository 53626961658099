<template>
	<Component
		:is="tagName"
		:href="href"
		:target="target"
		:rel="rel"
		:title="alt"
		:style="imageCSSVars"
		class="image"
		:class="{
			'image--grid': !isUnstyled,
			'image--unstyled': isUnstyled,
			'image--link': tagName === ANCHOR_TAG,
		}"
		@click="$emit('image-click', $event)"
	>
		<img
			v-qa="'builder-gridelement-gridimage'"
			:alt="alt"
			:src="src"
			:srcset="srcset"
			:sizes="sizes"
			:height="height"
			:width="width"
			:loading="isEager? 'eager' : 'lazy'"
			:class="{
				'image__image--zoom': isLightboxEnabled,
				'image__image--unstyled': isUnstyled,
				'image__image--cropped': !!cropCssVars,
				'image__image': !isUnstyled,
				'image__image--reset-m-position': resetMobilePosition
			}"
			@load="$emit('image-load')"
			v-on="{
				drag: preventDrag ? (e) => e.preventDefault() : () => null,
				dragstart: preventDrag ? (e) => e.preventDefault() : () => null,
			}"
		>
		<slot />
	</Component>
</template>

<script>
import {
	ANCHOR_TAG,
	DIV_TAG,
} from '@zyro-inc/site-modules/constants';

import { MOBILE_BLOCK_WIDTH } from '@zyro-inc/site-modules/components/blocks/layout/constants';

import {
	computed,
	defineComponent,
} from 'vue';

const ALLOWED_TAG_NAMES = [
	DIV_TAG,
	ANCHOR_TAG,
];

export default defineComponent({
	props: {
		alt: {
			type: String,
			default: null,
		},
		href: {
			type: String,
			default: null,
		},
		preventDrag: {
			type: Boolean,
			default: false,
		},
		sizes: {
			type: String,
			default: null,
		},
		src: {
			type: String,
			required: true,
		},
		srcset: {
			type: String,
			default: null,
		},
		tagName: {
			type: String,
			validator: (tag) => ALLOWED_TAG_NAMES.includes(tag),
			default: 'div',
		},
		target: {
			type: String,
			default: null,
		},
		rel: {
			type: String,
			default: null,
		},
		isLightboxEnabled: {
			type: Boolean,
			default: false,
		},
		isUnstyled: {
			type: Boolean,
			default: false,
		},
		resetMobilePosition: {
			type: Boolean,
			default: true,
		},
		width: {
			type: Number,
			default: null,
		},
		height: {
			type: Number,
			default: null,
		},
		cropCssVars: {
			type: Object,
			default: null,
		},
		isOverflowVisible: {
			type: Boolean,
			default: false,
		},
		isEager: {
			type: Boolean,
			default: false,
		},
		isMobileImage: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		// If image height is < 0 mobile values are not yet mapped
		const isMobileLayoutImage = computed(() => props.height > 0 && props.isMobileImage);

		return {
			ANCHOR_TAG,
			imageCSSVars: computed(() => ({
				'--overflow': props.isOverflowVisible ? 'visible' : null,
				...props.cropCssVars,
			})),
			widthCSSVar: computed(() => (isMobileLayoutImage.value ? `${(props.width * 100) / MOBILE_BLOCK_WIDTH}vw` : '100%')),
			heightCSSVar: computed(() => (isMobileLayoutImage.value ? `${(props.height * 100) / MOBILE_BLOCK_WIDTH}vw` : '100%')),
		};
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.image {
	overflow: var(--overflow, hidden);
	border-radius: var(--border-radius);

	&--grid {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
	}

	&--unstyled {
		// Fill element with image
		display: flex;
	}

	&--link {
		transition: filter 0.2s ease;

		&:hover {
			filter: contrast(0.8);
		}
	}

	&__image {
		// <img /> size is controlled by parent element (which is controlled by grid)
		position: absolute;
		display: block;
		object-fit: var(--object-fit, cover);

		&--zoom {
			cursor: zoom-in;
		}

		// When size is not controlled by grid
		&,
		&--unstyled {
			width: 100%;
			height: 100%;
		}

		&--cropped {
			top: var(--desktop-top);
			left: var(--desktop-left);
			width: var(--desktop-width);
			height: var(--desktop-height);
			transform: scale(var(--desktop-scale));
			transform-origin: 0 0;
		}
	}
}

@include site-engine-mobile {
	.image {
		&__image {
			&--cropped {
				top: var(--mobile-top);
				left: var(--mobile-left);
				width: var(--mobile-width);
				height: var(--mobile-height);
				transform: scale(var(--mobile-scale));
			}

			&--reset-m-position {
				position: static;
				width: 100%;
				height: auto;
			}
		}
	}
}

@media screen and (max-width: $media-mobile-builder) {
	.image {
		/* stylelint-disable value-keyword-case */
		width: v-bind(widthCSSVar);
		height: v-bind(heightCSSVar);
	}
}
</style>
